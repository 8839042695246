/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// NewStable.js
// NeuerStall Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 03.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

//
import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Store } from "react-notifications-component";

const NewStable = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [showButtons, setShowButtons] = useState(false);
  const [selectedStableId, setSelectedStableId] = useState("");
  const [availableDevices, setAvailableDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [chickenwatcher, setChickenwatcher] = useState([]);
  const [stables, setStables] = useState([]);
  const fetchStable = async () => {
    try {
      const operationID = currentUser.betriebID;
      const res = await axios.get("/api/stable/", {
        params: { operationID },
      });
      setStables(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchStable();
  }, []);

  const fetchChickenwatcher = async () => {
    try {
      const operationID = currentUser.betriebID;
      const res = await axios.get("/api/chickenwatcher/", {
        params: { operationID },
      });
      setChickenwatcher(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchChickenwatcher();
  }, []);

  const [selectedStable, setSelectedStable] = useState("");

  const [inputs, setInputs] = useState({
    bezeichnung: "",
    betriebID: currentUser.betriebID,
  });

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClickSave = async (e) => {
    e.preventDefault();
    console.log("inputs", inputs);

    try {
      const form = document.querySelector(".needs-validation");
      form.classList.add("was-validated");

      const areAllFieldsFilled =
        inputs.bezeichnung !== "" &&
        inputs.bezeichnung !== null &&
        inputs.stallnummer !== "" &&
        inputs.stallnummer !== null;

      if (!areAllFieldsFilled) {
        // Mindestens ein Feld ist nicht befüllt, zeige eine Fehlermeldung an
        console.log("Füllen Sie bitte die erforderlichen Daten aus.");
        alert("Füllen Sie bitte die erforderlichen Daten aus.");
        return;
      }

      await axios.post("/api/stable", inputs);
      Store.addNotification({
        title: "Erfolgreich",
        message: "Die Stallung wurde erfolgreich hinzugefügt!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
      fetchStable();
    } catch (err) {
      console.error("Fehler beim Speichern der Stallung: ", err);
      // Hier könntest du eine Benachrichtigung für den Fehler anzeigen
      Store.addNotification({
        title: "Fehler",
        message: "Beim Hinzufügen der Stallung ist ein Fehler aufgetreten.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
    }
  };

  const handleStableChangeForDelete = (e) => {
    setSelectedStableId(e.target.value);
  };

  const deleteStableHandler = async (e) => {
    e.preventDefault();
  
    if (!selectedStableId) {
      alert("Bitte wählen Sie eine Stallung aus, die gelöscht werden soll.");
      return;
    }
  
    const confirmation = window.confirm(
      "Sind Sie sicher, dass Sie diese Stallung löschen möchten?"
    );
  
    if (!confirmation) {
      console.log("Benutzer hat das Löschen abgebrochen");
      return;
    }
  
    try {
      await axios.put(`/api/stable/delete/${selectedStableId}`);
      Store.addNotification({
        title: "Erfolgreich",
        message: "Die Stallung wurde erfolgreich gelöscht!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
  
      setStables(stables.filter((stable) => stable.id !== parseInt(selectedStableId)));
      setSelectedStableId(""); // Setzen Sie die Auswahl zurück
      fetchStable();
    } catch (err) {
      console.error("Fehler beim Löschen der Stallung: ", err);
      Store.addNotification({
        title: "Fehler",
        message: "Beim Löschen der Stallung ist ein Fehler aufgetreten.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
    }
  };

  const handleClickUpdate = async (e) => {
    e.preventDefault();

    try {
      const form = document.querySelector(".needs-validation-update");
      form.classList.add("was-validated");

      const areAllFieldsFilled =
        inputs.bezeichnung !== "" &&
        inputs.bezeichnung !== null &&
        inputs.stallnummer !== "" &&
        inputs.stallnummer !== null;

      if (!areAllFieldsFilled) {
        // Mindestens ein Feld ist nicht befüllt, zeige eine Fehlermeldung an
        console.log("Füllen Sie bitte die erforderlichen Daten aus.");
        alert("Füllen Sie bitte die erforderlichen Daten aus.");
        return;
      }

      const updateData = {
        stallInfo: inputs, // Enthält Daten wie `bezeichnung`, `stallnummer`, etc.
        devices: selectedDevices, // Liste der Geräte, die aktualisiert werden sollen
      };

      const response = await axios.put("/api/stable/update", updateData);
      Store.addNotification({
        title: "Erfolgreich",
        message: "Die Stallung wurde erfolgreich aktualisiert!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
      fetchStable();
      fetchChickenwatcher();
    } catch (err) {
      console.error("Fehler beim Speichern der Stallung: ", err);
      // Hier könntest du eine Benachrichtigung für den Fehler anzeigen
      Store.addNotification({
        title: "Fehler",
        message: "Beim Aktualisieren der Stallung ist ein Fehler aufgetreten.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
    }
  };

  useEffect(() => {
    if (selectedStableId) {
      const selected = chickenwatcher.filter(
        (cw) => cw.stallID.toString() === selectedStableId
      );
      const available = chickenwatcher.filter(
        (cw) => cw.stallID.toString() !== selectedStableId
      );
      setSelectedDevices(selected);
      setAvailableDevices(available);
    } else {
      // Wenn keine Stallung ausgewählt ist, sind alle Geräte verfügbar
      setAvailableDevices(chickenwatcher);
      setSelectedDevices([]);
    }
  }, [selectedStableId, chickenwatcher]);

  const moveDeviceToSelected = (deviceId) => {
    const id = Number(deviceId); // Konvertierung zu Number, falls nötig
    const device = availableDevices.find((d) => d.id === id);
    if (device) {
      setAvailableDevices((prevDevices) =>
        prevDevices.filter((d) => d.id !== id)
      );
      setSelectedDevices((prevDevices) => [...prevDevices, device]);

      resetSelection("availableDevices");
    }
  };

  const moveDeviceToAvailable = (deviceId) => {
    const id = Number(deviceId); // Konvertierung zu Number, falls nötig
    const device = selectedDevices.find((d) => d.id === id);
    if (device) {
      setSelectedDevices((prevDevices) =>
        prevDevices.filter((d) => d.id !== id)
      );
      setAvailableDevices((prevDevices) => [...prevDevices, device]);

      resetSelection("selectedDevices");
    }
  };

  const resetSelection = (elementId) => {
    const selectElement = document.getElementById(elementId);
    if (selectElement) {
      selectElement.selectedIndex = -1; // Hebt die Auswahl aller Optionen auf
    }
  };

  const handleStableChange = (e) => {
    setSelectedStableId(e.target.value);
  };

  useEffect(() => {
    const selectedStable = stables.find(
      (s) => s.id.toString() === selectedStableId
    );
    if (selectedStable) {
      setInputs({
        ...inputs,
        bezeichnung: selectedStable.bezeichnung,
        id: selectedStable.id,
        // Füge hier weitere Felder hinzu, wie benötigt
      });
    }
  }, [selectedStableId, stables]);


  function checkDeviceType() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Prüfen auf Android
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // Prüfen auf Tablet
    if (/iPad|Tablet|PlayBook/i.test(userAgent)) {
      return "Tablet";
    }
    // Prüfen auf iOS, dass kein iPad ist (iPhone, iPod, etc.)
    if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    // Default zu Desktop
    return "Desktop";
  }

  useEffect(() => {
    const deviceType = checkDeviceType();
    setShowButtons(deviceType === "Android" || deviceType === "Tablet" || deviceType === "iOS");
  }, []);

  // Verwendung in einer Komponente
  const deviceType = checkDeviceType();

  return (
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">Stammdaten Stallung</h1>
                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3">
                        <p className="m-0 fw-bold">Neue Stallung</p>
                      </Card.Header>
                      <Card.Body>
                        <form className="needs-validation">
                          <Row>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="bezeichnung"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={""}
                                  onChange={handleChange}
                                  required
                                />
                                <label htmlFor="floatingInput">Name</label>
                              </div>
                            </Col>
                          </Row>
                          <Button
                            onClick={handleClickSave}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                        <p className="m-0 fw-bold">Stallung bearbeiten</p>
                        <form className="needs-validation-update">
                          <select
                            style={{ width: "auto" }}
                            onChange={handleStableChange}
                            value={selectedStableId}
                            className="form-control"
                            name="category"
                            required
                          >
                            <option value="">Bitte wählen</option>
                            {stables.map((stable) => (
                              <option key={stable.id} value={stable.id}>
                                {stable.bezeichnung}
                              </option>
                            ))}
                          </select>
                        </form>
                      </Card.Header>
                      <Card.Body>
                        <form className="needs-validation-updat">
                          <Row>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="bezeichnung"
                                  className="form-control"
                                  id="floatingInput"
                                  value={inputs.bezeichnung || ""}
                                  onChange={handleChange}
                                  required
                                />
                                <label htmlFor="floatingInput">Name</label>
                              </div>
                            </Col>
                          </Row>
                          <Row className="pb-2">
                            {" "}
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <select
                                  multiple
                                  id="availableDevices"
                                  className="form-control"
                                  style={{ height: "150px" }}
                                  onDoubleClick={(e) =>
                                    moveDeviceToSelected(e.target.value)
                                  }
                                >
                                  {availableDevices.map((device) => (
                                    <option key={device.id} value={device.id}>
                                      {device.token} - {device.id}{" "}
                                      {"(" + device.stallBezeichnung + ")"}
                                    </option>
                                  ))}
                                </select>
                                <label htmlFor="floatingInput">
                                  Verfügbare Geräte
                                </label>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <select
                                  multiple
                                  id="selectedDevices"
                                  className="form-control"
                                  style={{ height: "150px" }}
                                  onDoubleClick={(e) =>
                                    moveDeviceToAvailable(e.target.value)
                                  }
                                >
                                  {selectedDevices.map((device) => (
                                    <option key={device.id} value={device.id}>
                                      {device.token}
                                    </option>
                                  ))}
                                </select>
                                <label>Ausgewählte Geräte</label>
                              </div>
                            </Col>

                            {deviceType === "Desktop" && (
                              <React.Fragment>
                                <Col sm={6}>
                                  <b className="">
                                    Mit einem Doppelklick kann man die
                                    Chickenwatcher zuweisen.
                                  </b>
                                </Col>
                              </React.Fragment>
                            )}
                            {showButtons && (
                              <React.Fragment>
                                <Col sm={6}>
                                  <Button
                                    className="submitbutton"
                                    onClick={() => {
                                      const selectedValues = Array.from(
                                        document.getElementById(
                                          "availableDevices"
                                        ).selectedOptions
                                      ).map((option) => option.value);
                                      selectedValues.forEach(
                                        moveDeviceToSelected
                                      );
                                    }}
                                  >
                                    Zu ausgewählten hinzufügen
                                  </Button>
                                </Col>
                                <Col sm={6}>
                                  <Button
                                    className="submitbutton"
                                    onClick={() => {
                                      const selectedValues = Array.from(
                                        document.getElementById(
                                          "selectedDevices"
                                        ).selectedOptions
                                      ).map((option) => option.value);
                                      selectedValues.forEach(
                                        moveDeviceToAvailable
                                      );
                                    }}
                                  >
                                    Zu verfügbaren hinzufügen
                                  </Button>
                                </Col>
                              </React.Fragment>
                            )}
                          </Row>

                          <Button
                            onClick={handleClickUpdate}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3">
                        <p className="m-0 fw-bold">Stallung löschen</p>
                      </Card.Header>
                      <Card.Body>
                        <form>
                          {/* Löschen */}
                          {/* Stallung löschen */}
                          <div className="form-floating mb-3">
                            <select
                              value={selectedStableId}
                              onChange={handleStableChangeForDelete}
                              className="form-control"
                              name="stableID"
                            >
                              <option hidden value="">
                                -- Bitte Stallung auswählen --
                              </option>
                              {stables.map((stable, index) => (
                                <option key={index} value={stable.id}>
                                  {stable.bezeichnung}
                                </option>
                              ))}
                            </select>
                            <label htmlFor="floatingInput">
                              Stallung löschen:
                            </label>
                          </div>

                          <Button
                            onClick={deleteStableHandler}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default NewStable;
