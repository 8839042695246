/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Photos.js
// Fotos Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 03.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Card,
  Table,
  Form,
  Row,
  Col,
  Pagination,
  Button,
} from "react-bootstrap";
import axios from "axios";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import moment from 'moment';
import { LightboxKey, Team } from "../env.js";

import { SlideshowLightbox, initLightboxJS } from "lightbox.js-react";
import "lightbox.js-react/";
import "../css/imageCarousel.scss";
import "lightbox.js-react/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import defaultImage from "../images/logo_CW.png";
import { Store } from "react-notifications-component";
import '@fortawesome/fontawesome-free/css/all.min.css';



function History() {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [folk, setFolk] = useState([]);
  const [selectedFolkID, setSelectedFolkID] = useState("");
  const [tableData, setTableData] = useState([]);
  console.log(folk);



  useEffect(() => {
    const fetchFolk = async () => {
      try {
        const operationID = currentUser.betriebID;
        const res = await axios.get("/api/folk/selectAllFlock/", {
          params: { operationID },
        });
        setFolk(res.data);
        
        if (res.data.length > 0) {
          setSelectedFolkID(res.data[0].id.toString());
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchFolk();
  }, [currentUser.betriebID]);

  const fetchData = async () => {
    if (!selectedFolkID) return;
    try {
      const res = await axios.get("/api/datas", {
        params: { selectedFolkID },
      });

      setTableData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedFolkID]);

  const handleDropdownChange = (e) => {
    setSelectedFolkID(e.target.value);
  };

  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);



  


  window.moment = moment;
  moment.locale();

  //musste die logik umdrehen das es klappt mit der filterung

  
  const positivOptions = [
    { id: 1, label: 'Negativ' },
    { id: 2, label: 'Positiv' },
  ];


  /**
   * Spalten und Überschriften
   */
  const columns = [
    {
      name: "id",
      header: "ID",
      minWidth: 50,
      maxWidth: 100,
      defaultFlex: 1,
      filterEditor: NumberFilter,
    },
    {
        name: "bezeichnung",
        header: "Herden-Bezeichnung",
        type: "string",
        minWidth: 150,
        defaultFlex: 1,
    },
    {
      name: 'einstalldatum',
      header: 'Einstalldatum',
      defaultFlex: 1,
      dateFormat: 'DD.MM.YYYY', // korrektes Format hier einstellen
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        return {
          dateFormat: 'YYYY-MM-DD', // oder welches Format Sie für den Filter bevorzugen
          placeholder: index === 1 ? '' : '' // oder entsprechenden Platzhaltertext
        };
      },
      render: ({ value }) => moment(value).format('DD.MM.YYYY'), // Formatierung hier
    },
    {
        name: 'ausstalldatum',
        header: 'Ausstalldatum',
        defaultFlex: 1,
        dateFormat: 'DD.MM.YYYY', // korrektes Format hier einstellen
        filterEditor: DateFilter,
        filterEditorProps: (props, { index }) => {
          return {
            dateFormat: 'YYYY-MM-DD', // oder welches Format Sie für den Filter bevorzugen
            placeholder: index === 1 ? '' : '' // oder entsprechenden Platzhaltertext
          };
        },
        render: ({ value }) => moment(value).format('DD.MM.YYYY'), // Formatierung hier
    },
    {
      name: "rassenBezeichnung",
      header: "Rasse",
      type: "string",
      minWidth: 150,
      defaultFlex: 1,
    },
    {
        enableColumnFilterContextMenu: false,
        name: "aktiv",
        header: "Aktiv",
        minWidth: 150,
        defaultFlex: 1,
        filterEditor: SelectFilter,
          filterEditorProps: {
            placeholder: 'Alle',
            dataSource: positivOptions,
          },
          render: ({ value }) => 
            value === 1 
              ? <span style={{ color: 'red' }}>Positiv</span> 
              : value === 0 
                ? "Negativ" 
                : ""
  
        },



     
      
  ];






  /**
   * Anlegen von übergeordneten Gruppen
   */
  const groups = [{ name: "other", header: "Sonstiges" }];

  const defaultSortInfo = { name: 'datum', dir: -1 }
  /**
   * Filterung
   */
  const filterValue = [
    { name: "id", operator: "gte", type: "number" },
    { name: "bezeichnung", operator: "startsWith", type: "string", value: "" },
    {
      name: "einstalldatum",
      operator: "afterOrOn",
      type: "date",
      value: "",
      dir: -1
    },
    {
        name: "ausstalldatum",
        operator: "afterOrOn",
        type: "date",
        value: "",
        dir: -1
      },
    { name: "rassenBezeichnung", operator: "startsWith", type: "string", value: "" },
    { name: "aktiv", operator: "neq", type: "select", value: "" },

  ];

  const [selected, setSelected] = useState({});



  const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
    pageText:'Seite ',
    ofText:' von ',
    perPageText:'Ergebnisse pro Seite',
    showingText:'Zeigt '

  })
  
  const selectedHerde = folk.find(herde => herde.id.toString() === selectedFolkID);

  const gridStyle = { minHeight: 550 };

  return (
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">
                Übersicht Historie Herden
                </h1>
                <Card className="shadow">
                  <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                    <p className="m-0 fw-bold">Herden Daten</p>


                  </Card.Header>

                  <Card.Body>
                    <Row>
                      <ReactDataGrid
                        idProperty="organisatorID"
                        defaultFilterValue={filterValue}
                        columns={columns}
                        groups={groups}
                        editable={false}
                        dataSource={folk}
                        style={gridStyle}
                        defaultLimit={10}
                        selected={selected}
                        
                        pagination
                        i18n={i18n}
                        defaultSortInfo={defaultSortInfo}
  
                      />
                    </Row>


                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default History;
