/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Navbar.js
// Hauptdatei der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 03.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React, { useContext, useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';

import { Container, Row, Col } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AuthContext } from "../context/authContext";
import axios from "axios";

function NavScrollExample() {
  const { currentUser } = useContext(AuthContext);
  const [dateTime, setDateTime] = useState(new Date());

  const operationID = currentUser.betriebID;
  const [operationData, setOperationData]  = useState([]);
  useEffect(() => {
    const fetchtableData = async () => {
      try {

        const res = await axios.get("/api/operation/", {
          params: { operationID, bust: Date.now() },
        });
        setOperationData(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchtableData();
  }, []);




  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString();
  };


  return (
    <Navbar expand="lg" 
      className="bg-white shadow"
    >
      <Container fluid style={{ height: "70px" }}>
      <Col xs={6} sm={6} md={5} style={{ whiteSpace: 'nowrap' }}>
        <div><span>Betrieb:</span></div>
        <div><span>{operationData[0]?.bezeichnung}</span></div>
      </Col>

      <Col xs={6} sm={5} md={5} style={{ whiteSpace: 'nowrap' }}>
        <div>Datum: <span>{formatDate(dateTime)}</span></div>
        <div>Uhrzeit: <span>{formatTime(dateTime)}</span></div>
      </Col>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;