/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Profile.js
// Profil Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 03.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/main.scss";
import { Store } from "react-notifications-component";

const User = () => {

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [userData, setUserData]  = useState([]);

  useEffect(() => {
    const fetchtableData = async () => {
        try {
          let userID;
          if (currentUser.id != "") {
            userID = currentUser.id;

            const res = await axios.get("/api/user", {
              params: { userID
                , bust: Date.now() 
              },
            });
          
        
            setUserData(res.data[0]);
          }
        } catch (err) {
          console.log(err);
        }
    };
    fetchtableData();
  }, []);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const res = await axios.get("/api/country");
        setCountries(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCountries();
  }, []);

  const [inputs, setInputs] = useState({
    id: userData.id,
    benutzername: userData.benutzername,
    betriebID: userData.betriebID,
    betrieb: userData.betrieb,
    vorname: userData.vorname,
    nachname: userData.nachname,
    adresse: userData.adresse,
    plz: userData.plz,
    ort: userData.ort,
    landID: userData.landID,
    land: userData.land,
    email: userData.email,
    telefon: userData.telefon,
    betriebsnr: userData.betriebsnr,
  });

  useEffect(() => {
    setInputs({
      id: userData.id,
      benutzername: userData.benutzername,
      betriebID: userData.betriebID,
      betrieb: userData.betrieb,
      vorname: userData.vorname,
      nachname: userData.nachname,
      adresse: userData.adresse,
      plz: userData.plz,
      ort: userData.ort,
      landID: userData.landID,
      land: userData.land,
      email: userData.email,
      telefon: userData.telefon,
    });
  }, [userData]);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClickSave = async (e) => {
    e.preventDefault();

  try {

    const form = document.querySelector(".needs-validation");
    form.classList.add("was-validated");


    const areAllFieldsFilled = inputs.id !== "" &&
  (inputs.benutzername !== "" && inputs.benutzername !== null) &&
  (inputs.vorname !== "" && inputs.vorname !== null) &&
  (inputs.nachname !== "" && inputs.nachname !== null) &&
  (inputs.adresse !== "" && inputs.adresse !== null) &&
  (inputs.plz !== "" && inputs.plz !== null) &&
  (inputs.ort !== "" && inputs.ort !== null) &&
  (inputs.landID !== "" && inputs.landID !== null) &&
  (inputs.email !== "" && inputs.email !== null) &&
  (inputs.telefon !== "" && inputs.telefon !== null);



    if (!areAllFieldsFilled) {
    // Mindestens ein Feld ist nicht befüllt, zeige eine Fehlermeldung an
    console.log("Füllen Sie bitte die erforderlichen Daten aus.");
    alert("Füllen Sie bitte die erforderlichen Daten aus.");
    return
    }

   
      await axios.put("/api/user", inputs);
      Store.addNotification({
        title: "Erfolgreich",
        message: "Die Benutzer-Daten wurden aktualisiert!!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
      localStorage.setItem("user", JSON.stringify(inputs));
    } catch (err) {
      console.error("Fehler beim Speichern der Stallung: ", err);
      // Hier könntest du eine Benachrichtigung für den Fehler anzeigen
      Store.addNotification({
          title: "Fehler",
          message: "Beim Hinzufügen der Benutzer-Daten ist ein Fehler aufgetreten.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
          duration: 6000,
          onScreen: true
          }
      });
    }
  };

  return (
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">Administration Benutzer</h1>
                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3">
                        <p className="m-0 fw-bold">Benutzer-Daten</p>
                      </Card.Header>
                      <Card.Body>
                        <form className="needs-validation">
                          <Row>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="benutzername"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={userData.benutzername}
                                  onChange={handleChange}
                                  required
                                />
                                <label htmlFor="floatingInput">Benutzername</label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="email"
                                  className="form-control "
                                  id="floatingInput"
                                  defaultValue={userData.email}
                                  onChange={handleChange}
                                  required
                                />
                                <label>Email</label>
                              </div>
                            </Col>
                          </Row>

                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="vorname"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={userData.vorname}
                                  onChange={handleChange}
                                  required
                                />
                                <label htmlFor="floatingInput">Vorname</label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="nachname"
                                  className="form-control "
                                  id="floatingInput"
                                  defaultValue={userData.nachname}
                                  onChange={handleChange}
                                  required
                                />
                                <label>Nachname</label>
                              </div>
                            </Col>
                          </Row>

                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="betrieb"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={userData.betrieb}
                                  onChange={handleChange}
                                  required
                                  disabled
                                />
                                <label htmlFor="floatingInput">Betrieb</label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="telefon"
                                  className="form-control "
                                  id="floatingInput"
                                  defaultValue={userData.telefon}
                                  onChange={handleChange}
                                  required
                                />
                                <label>Tel</label>
                              </div>
                            </Col>
                          </Row>

                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="adresse"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={userData.adresse}
                                  onChange={handleChange}
                                  required
                                />
                                <label htmlFor="floatingInput">Adresse</label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="plz"
                                  className="form-control "
                                  id="floatingInput"
                                  defaultValue={userData.plz}
                                  onChange={handleChange}
                                  required
                                />
                                <label>Plz</label>
                              </div>
                            </Col>
                          </Row>

                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="ort"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={userData.ort}
                                  onChange={handleChange}
                                  required
                                />
                                <label htmlFor="floatingInput">Ort</label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <select
                                  className="form-control"
                                  defaultValue={inputs.land}
                                  onChange={handleChange}
                                  name="landID"
                                  required
                                >
                                  {userData.land ? (
                                    <option hidden value="">
                                      {userData.land}
                                    </option>
                                  ) : (
                                    <option hidden value="">
                                      -- Bitte ausw&auml;hlen --
                                    </option>
                                  )}

                                  {countries.map((country) => {
                                    return (
                                      <option
                                        key={country.ID}
                                        value={country.ID}
                                      >
                                        {country.Bezeichnung}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label htmlFor="floatingSelect">Land</label>
                              </div>
                            </Col>
                          </Row>

                          <Button
                            onClick={handleClickSave}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default User;
