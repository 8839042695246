/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Overview.js
// Übersicht page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 04.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/main.scss";
import Chart from "chart.js";
import PieChartComponent from "../components/PieChartComponent";
import LineChartComponent from '../components/LineChartComponent';
import StatisticsOverview from "../components/StatisticsOverview";

const Overview = (info) => {
  let [currentUser, setCurrentUser] = useState(null);

  currentUser = info.info;

  const [operationData, setOperationData] = useState([]);

  const [selectedFolkId, setSelectedFolkId] = useState("");

  const [folk, setFolk] = useState([]);

  const [statisticsData, setStatisticsData] = useState([]);
  const [todayStatisticsData, setTodayStatisticsData] = useState([]);
  const [allDayStatisticsData, setAllDayStatisticsData] = useState([]);

  useEffect(() => {
    const fetchtOperationData = async () => {
      try {
        let operationID;
        if (currentUser != null) {
          operationID = currentUser.betriebID;

          const res = await axios.get("/api/operation/", {
            params: { operationID, bust: Date.now() },
          });

          setOperationData(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchtOperationData();
  }, []);


  useEffect(() => {
    const fetchFolk = async () => {
      try {
        if (currentUser != null) {
          const operationID = currentUser.betriebID;
          const res = await axios.get("/api/folk", {
            params: { operationID },
          });
          setFolk(res.data);

          if (res.data.length > 0) {
            setSelectedFolkId(res.data[0].id.toString());
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchFolk();
  }, []);

  const lineChartLabels = allDayStatisticsData.map(row => row.tag);
  const lineChartData = allDayStatisticsData.map(row => row.durchschnitt);

  const handleFolkChange = (e) => {
    setSelectedFolkId(e.target.value);
  };

  useEffect(() => {
    const fetchStatisticsData = async () => {
      if (selectedFolkId) {
        // Stelle sicher, dass eine herdeID ausgewählt wurde
        try {
          const res = await axios.get("/api/statistics/", {
            params: { herdeID: selectedFolkId, bust: Date.now() },
          });

          setStatisticsData(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchStatisticsData();
  }, [selectedFolkId]);

  useEffect(() => {
    const fetchTodayStatisticsData = async () => {
      if (selectedFolkId) {
        // Stelle sicher, dass eine herdeID ausgewählt wurde
        try {
          const res = await axios.get("/api/statistics/today-statistics", {
            params: { herdeID: selectedFolkId, bust: Date.now() },
          });

          setTodayStatisticsData(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchTodayStatisticsData();
  }, [selectedFolkId]);

  ///TODO statistics/selectStatsTillCurrentDate/ 
  //In Verwendung außerhalb von Showcases sollte '/allday-statistics' verwendet werden, da statistics/selectStatsTillCurrentDate/ nur die Daten bis zum aktuellen Datum zurück gibt
  useEffect(() => {
    const fetchAllDayStatisticsData = async () => {
      if (selectedFolkId) {
        // Stelle sicher, dass eine herdeID ausgewählt wurde
        try {
          const res = await axios.get("/api/statistics/selectStatsTillCurrentDate/", {
            params: { herdeID: selectedFolkId, bust: Date.now() },
          });

          setAllDayStatisticsData(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchAllDayStatisticsData();
  }, [selectedFolkId]);

  const selectedHerde = folk.find(
    (herde) => herde.id.toString() === selectedFolkId
  );

  const chartData =
    statisticsData.length > 0
      ? [statisticsData[0].herdeistalter, statisticsData[0].altersdifferenz]
      : [0, 0];

  const statusChartData = selectedHerde
    ? [selectedHerde.positiveDaten || 0, selectedHerde.negativDaten || 0]
    : [0, 0];

  return (
    <Container fluid>
  <div className="main">
    <Row>
      <Col>
        <div className="contentbox">
          <h1 className="title">Dashboard</h1>

          {/* Herde Diagramm Section */}
          <Row className="mb-3">
            <Col>
              <Card className="shadow mb-3">
                <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                  <p className="m-0 fw-bold">Herde Diagramm</p>

                  <select
                    style={{ width: "auto" }}
                    className="form-control"
                    value={selectedFolkId}
                    onChange={handleFolkChange}
                    required
                  >
                    {folk.map((f) => (
                      <option key={f.id} value={f.id}>
                        {f.bezeichnung}
                      </option>
                    ))}
                  </select>
                </Card.Header>

                <Card.Body>
                  <Row>
                    {/* Left Column: Text */}
<Col xs={12} md={6} className="d-flex flex-column">
  {selectedHerde ? (
    <>
      <div className="mb-3">
        <b>Einstalldatum: </b> {selectedHerde.einstallDatum} <br />
        <b>Ausstalldatum: </b> {selectedHerde.ausstallDatum} <br />
        <b>Rasse: </b> {selectedHerde.rassenBezeichnung} <br />
        <b>Anzahl: </b> {selectedHerde.anzahl} <br />
        <b>Stall: </b> {selectedHerde.stallBezeichnung} <br />
      </div>
      {/* Line Above Befund */}
      <hr className="mb-3" />
      <div className="mt-n3">
        <b>Befund:</b>
        <div className="mb-2">
          <b>
            {selectedHerde.positiveDaten} positiv von{" "}
            {selectedHerde.negativDaten}
          </b>{" "}
        </div>
        {todayStatisticsData && todayStatisticsData.length > 0 ? (
          <div>
            <b>
              Heute wurden{" "}
              <span style={{ color: "red" }}>
                {todayStatisticsData[0].anzahl}
              </span>{" "}
              Datensätze aufgenommen und das Durchschnittsgewicht beträgt{" "}
              <span style={{ color: "red", marginBottom: '50px', display: 'inline-block' }}>
                {todayStatisticsData[0].durchschnitt
                  ?.toFixed(2)
                  .replace(".", ",") || "0,00"}g
              </span>.
            </b>
          </div>
        ) : (
          <div>Keine Daten für heute verfügbar.</div>
        )}
      </div>
    </>
  ) : (
    <div>Herde auswählen</div>
  )}
</Col>

                    {/* Right Column: Pie Charts */}
<Col xs={12} md={6} className="d-flex flex-column align-items-center">
  <div className="chart-container mb-3" style={{ width: '100%', maxWidth: '400px' }}>
    {selectedHerde ? (
      <PieChartComponent
        data={chartData}
        title="Altersverteilung"
        style={{ width: "100%", height: "180px" }}
      />
    ) : (
      <div>Herde auswählen</div>
    )}
  </div>
  <div className="chart-container" style={{ width: '100%', maxWidth: '400px' }}>
    {selectedHerde ? (
      <PieChartComponent
        data={statusChartData}
        title="Status der Daten"
        style={{ width: "100%", height: "180px" }}
      />
    ) : (
      <div>Herde auswählen</div>
    )}
  </div>
</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Statistics Overview and Line Chart Section */}
          <Row className="mb-3">
            {/* Statistics Overview */}
            <Col xs={12} md={6} className="mb-3 mb-md-0">
              <Card className="shadow mb-3">
                <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                  <p className="m-0 fw-bold">Statistiken Übersicht</p>

                  <select
                    style={{ width: "auto" }}
                    className="form-control"
                    value={selectedFolkId}
                    onChange={handleFolkChange}
                    required
                  >
                    {folk.map((f) => (
                      <option key={f.id} value={f.id}>
                        {f.bezeichnung}
                      </option>
                    ))}
                  </select>
                </Card.Header>

                <Card.Body>
  <Row>
    <Col xs={12} className="d-flex justify-content-center">
      <div style={{ width: '100%', maxWidth: '100%', padding: '20px' }}>
        <StatisticsOverview data={allDayStatisticsData} />
      </div>
    </Col>
  </Row>
</Card.Body>
</Card>
</Col>

{/* Line Chart */}
<Col xs={12} md={6}>
  <Card className="shadow mb-3">
    <Card.Header className="py-3 d-flex justify-content-between align-items-center">
      <p className="m-0 fw-bold">Durchschnittsgewicht im Zeitverlauf</p>
    </Card.Header>

    <Card.Body>
      <Row>
        <Col xs={12} className="d-flex justify-content-center">
          <div style={{ width: '100%', padding: '20px', minWidth: '400px', minHeight: '400px' }}>
            <LineChartComponent 
              data={lineChartData} 
              labels={lineChartLabels} 
              title="Durchschnittsgewicht im Zeitverlauf" 
              style={{ 
                width: '100%', 
                height: '300px', 
                maxWidth: '100%', 
                minHeight: '300px'  // Enforcing minimum height on the chart itself
              }} 
            />
          </div>
        </Col>
      </Row>
    </Card.Body>
  </Card>
</Col>

          </Row>
        </div>
      </Col>
    </Row>
  </div>
</Container>


  );
};

export default Overview;
