/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Sidebar.js
// Hauptdatei der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 04.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////


import  { React,useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { useEffect } from "react";
import { useState } from "react";
import {
  PieChart,
  JournalBookmark,
  Archive,
  Folder,
  BoxArrowInLeft,
  List,
} from "react-bootstrap-icons";


import Logo from "../images/chiwa_logo_colored_grey.svg";


import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";

import { Container, Row, Col } from "react-bootstrap";
import Footer from "react-bootstrap/ModalFooter";

import "../css/sidebar.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const NavSidebar = () => {
  const { collapseSidebar } = useProSidebar();
   const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  // const [data, setData] = useState([]);





  // function collapseSidebarDef(){

  //   let collapsed = document
  //     .getElementById("sidebar")
  //     .classList.contains("ps-collapsed");

  //   collapseSidebar();

  //   console.log(document.getElementById("sidebar"));
  //   let picture = document.getElementById("sidebarlogo");

  //   if(collapsed== false){
  //    picture.style.visibility = "hidden";
  //   }
  //   else{
  //           picture.style.visibility = "visible";

  //   }


  // }



  // Optionale Variante

  const [isCollapsed, setIsCollapsed] = useState(false);

  function collapseSidebarDef() {
    let collapsed = document.getElementById("sidebar").classList.contains("ps-collapsed");

    collapseSidebar();
    setIsCollapsed(!collapsed);

    let picture = document.getElementById("sidebarlogo");
    if (!collapsed) {
      picture.style.visibility = "hidden";
    } else {
      picture.style.visibility = "visible";
    }
  }

// 

  return (
    <div className="sidebarbox" style={{ display: "flex", height: "100vh" }}>
      <Sidebar id="sidebar" backgroundColor= "none" style={{ height: "100%",backgroundColor: "none" }}>
        <Container fluid className="sidebarheader">
          <Row className="sidebarrow">
          <Col xs={7}>
              <h1>
                
                {!isCollapsed && <p> Hallo, {currentUser?.vorname}</p>}
              </h1>
            </Col>
            <Col></Col>
            <Col>
              <List onClick={collapseSidebarDef}></List>
            </Col>
          </Row>
          <Row className="sidebarrow">
            <Link to="/">
              <img id="sidebarlogo" className="logo" src={Logo} alt="" />
            </Link>
          </Row>
        </Container>
        <Menu>
          <MenuItem
            component={<Link to="/" />}
            icon={
              <i className="">
                <PieChart></PieChart>
              </i>
            }
          >
            Dashboard
          </MenuItem>

          <SubMenu 
            label="Administration"
            icon={
              <i className="">
                <JournalBookmark></JournalBookmark>
              </i>
            }
          >
            <MenuItem component={<Link to="/user" />}>
              Benutzer
            </MenuItem>
            <MenuItem component={<Link to="/operation" />}>
              Betrieb
            </MenuItem>
          </SubMenu>


          <SubMenu 
            label="Stammdaten"
            icon={
              <i className="">
                <Archive></Archive>
              </i>
            }
          >
            <MenuItem component={<Link to="/history" />}>
              Historie Herden
            </MenuItem>
            <MenuItem component={<Link to="/newstable" />}>
              Stallung
            </MenuItem>
            <MenuItem component={<Link to="/newfolk" />}>
              Neue Herde
            </MenuItem>
            <MenuItem component={<Link to="/allocation" />}>
              Zuweisung
            </MenuItem>
          </SubMenu>

          <MenuItem
            component={<Link to="/photos" />}
            icon={
              <i className="">
                <Folder></Folder>
              </i>
            }
          >
            Daten
          </MenuItem>



          {
          currentUser ? (
            <MenuItem
              onClick={logout}
              className="logout"
              icon={
                <i className="">
                  <BoxArrowInLeft></BoxArrowInLeft>
                </i>
              }
            >
              Logout
            </MenuItem>

          ) : (
            navigate("/login")
          )}
        </Menu>
      </Sidebar>
    </div>
  );
};

export default NavSidebar;
