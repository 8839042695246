//Zuweisung
/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Allocation
// Zuweisung Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 03.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/main.scss";
import { Store } from "react-notifications-component";

const Allocation = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [folk, setFolk] = useState([]);

  const fetchFolk = async () => {
    try {
      const operationID = currentUser.betriebID;
      const res = await axios.get("/api/folk", {
        params: { operationID },
      });
      setFolk(res.data);

      if (res.data.length > 0) {
        setSelectedFolkId(res.data[0].id.toString());
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFolk();
  }, [currentUser.betriebID]);

  const [stables, setStables] = useState([]);

  const fetchStable = async () => {
    try {
      const operationID = currentUser.betriebID;
      const res = await axios.get("/api/stable/", {
        params: { operationID },
      });
      setStables(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchStable();
  }, []);

  const [breeds, setBreeds] = useState([]);

  const fetchBreeds = async () => {
    try {
      const res = await axios.get("/api/breeds");
      setBreeds(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBreeds();
  }, []);

  const [selectedBreedId, setSelectedBreedId] = useState(null);
  const handleBreedChange = (e) => {
    const selectedId = e.target.value;
    setSelectedBreedId(selectedId);
    setInputs((prev) => ({ ...prev, rasseID: selectedId }));
  };
  const selectedBreedUsage = breeds.find(
    (breed) => breed.id.toString() === selectedBreedId
  )?.verwendungbezeichnung;

  const [inputs, setInputs] = useState({
    betriebID: currentUser.betriebID,
  });

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClickSave = async (e) => {
    e.preventDefault();

    try {
      const form = document.querySelector(".needs-validation");
      form.classList.add("was-validated");

      const areAllFieldsFilled =
        inputs.ausstallung !== "" &&
        inputs.ausstallung !== null &&
        inputs.bezeichnung !== "" &&
        inputs.bezeichnung !== null &&
        inputs.einstallung !== "" &&
        inputs.einstallung !== null &&
        inputs.rasseID !== "" &&
        inputs.rasseID !== null &&
        inputs.stallID !== "" &&
        inputs.stallID !== null &&
        inputs.anzahl !== "" &&
        inputs.anzahl !== null &&
        inputs.aktiv !== "" &&
        inputs.aktiv !== null;

      if (!areAllFieldsFilled) {
        // Mindestens ein Feld ist nicht befüllt, zeige eine Fehlermeldung an
        console.log("Füllen Sie bitte die erforderlichen Daten aus.");
        alert("Füllen Sie bitte die erforderlichen Daten aus.");
        return;
      }

      await axios.put("/api/folk", inputs);
      Store.addNotification({
        title: "Erfolgreich",
        message: "Die Herden-Daten wurden aktualisiert!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
      fetchFolk();
      fetchStable();
      fetchBreeds();
    } catch (err) {
      console.error("Fehler beim Speichern der Herden-Daten: ", err);
      Store.addNotification({
        title: "Fehler",
        message:
          "Beim Hinzufügen der Betriebs-Daten ist ein Fehler aufgetreten.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true,
        },
      });
    }
  };

  const [selectedFolkId, setSelectedFolkId] = useState("");

  const handleFolkChange = (e) => {
    const selectedId = e.target.value;
    setSelectedFolkId(selectedId);
    // Hier könntest du die Inputs basierend auf der Auswahl setzen
    const selectedFolk = folk.find((f) => f.id.toString() === selectedId);
    if (selectedFolk) {
      setInputs({
        ...inputs,
        // Setze hier deine spezifischen Felder basierend auf `selectedFolk`
      });
    }
  };

  useEffect(() => {
    const selectedFolk = folk.find((f) => f.id.toString() === selectedFolkId);
    if (selectedFolk) {
      setInputs({
        bezeichnung: selectedFolk.bezeichnung,
        einstallung: selectedFolk.einstalldatum,
        ausstallung: selectedFolk.ausstalldatum,
        rasseID: selectedFolk.rasseID,
        stallID: selectedFolk.stallID,
        rassenBezeichnung: selectedFolk.rassenBezeichnung,
        stallBezeichnung: selectedFolk.stallBezeichnung,
        nutzart: selectedFolk.verwendungBezeichnung,
        betriebID: selectedFolk.betriebID,
        anzahl: selectedFolk.anzahl,
        aktiv: selectedFolk.aktiv,
        herdenID: selectedFolk.id,
        // Füge hier weitere Felder hinzu, wie benötigt
      });
    }
  }, [selectedFolkId, folk]);

  return (
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">Stammdaten Zuweisung</h1>
                <Row className="mb-3">
                  <Col>
                    <Card className="shadow mb-3">
                      <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                        <p className="m-0 fw-bold">Zuweisung der Daten</p>
                        <select
                          style={{ width: "auto" }}
                          className="form-control"
                          name="category"
                          value={selectedFolkId}
                          onChange={handleFolkChange}
                          required
                        >
                          {folk.map((f) => (
                            <option key={f.id} value={f.id}>
                              {f.bezeichnung}
                            </option>
                          ))}
                        </select>
                      </Card.Header>
                      <Card.Body>
                        <form className="needs-validation">
                          <Row>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="bezeichnung"
                                  className="form-control"
                                  id="floatingInput"
                                  value={inputs.bezeichnung || ""}
                                  onChange={handleChange}
                                  required
                                />
                                <label htmlFor="floatingInput">
                                  Herden-Bezeichnung
                                </label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="date"
                                  className="form-control"
                                  id="floatingInput"
                                  name="einstallung"
                                  value={inputs.einstallung || ""}
                                  onChange={handleChange}
                                  required
                                />
                                <label>Einstallung</label>
                              </div>
                            </Col>
                          </Row>
                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <select
                                  className="form-control"
                                  value={inputs.rasseID || ""}
                                  onChange={handleBreedChange}
                                  name="rasseID"
                                  required
                                >
                                  <option value="">
                                    -- Bitte ausw&auml;hlen --
                                  </option>
                                  {breeds.map((breed) => (
                                    <option key={breed.id} value={breed.id}>
                                      {breed.bezeichnung}
                                    </option>
                                  ))}
                                </select>
                                <label htmlFor="floatingSelect">Rasse</label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="date"
                                  className="form-control"
                                  id="floatingInput"
                                  name="ausstallung"
                                  value={inputs.ausstallung || ""}
                                  onChange={handleChange}
                                  required
                                />
                                <label>Ausstallung</label>
                              </div>
                            </Col>
                          </Row>

                          <Row className="pb-2">
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  name="nutzart"
                                  className="form-control"
                                  id="floatingInput"
                                  defaultValue={
                                    selectedBreedUsage || inputs.nutzart || ""
                                  }
                                  readOnly
                                />
                                <label htmlFor="floatingInput">Nutzart</label>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <input
                                  type="number"
                                  name="anzahl"
                                  className="form-control"
                                  id="floatingInput"
                                  value={inputs.anzahl || ""}
                                  onChange={handleChange}
                                  min="0"
                                  step="1"
                                  required
                                />
                                <label htmlFor="floatingInput">Anzahl</label>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <select
                                  className="form-control"
                                  value={inputs.stallID || ""}
                                  onChange={handleChange}
                                  name="stallID"
                                  required
                                >
                                  <option value="">
                                    -- Bitte ausw&auml;hlen --
                                  </option>
                                  {stables.map((stable) => (
                                    <option key={stable.id} value={stable.id}>
                                      {stable.bezeichnung}
                                    </option>
                                  ))}
                                </select>
                                <label htmlFor="floatingSelect">Stall</label>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6}>
                              <div className="form-floating mb-3">
                                <select
                                  className="form-control"
                                  id="floatingSelect"
                                  name="aktiv"
                                  value={inputs.aktiv || ""}
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="1">Aktiv</option>
                                  <option value="0">Inaktiv</option>
                                </select>
                                <label htmlFor="floatingSelect">aktiv</label>
                              </div>
                            </Col>
                          </Row>

                          <Button
                            onClick={handleClickSave}
                            className="submitbutton"
                          >
                            &Auml;nderungen speichern
                          </Button>
                        </form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default Allocation;
