/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Home.jsx
// View für Home
//
// Author:          Thomas Wippel
// Erstellt am:     23.01.2023
// Letzte Änderung: 10.02.2023
// von:             Thomas Wippel
//
///////////////////////////////////////////////////////////////////////////////////

import React from "react";

import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/main.scss";
import Chart from "chart.js";
import PieChartComponent from "../components/PieChartComponent";
import Overview from "./Overview.jsx";

const Home = () => {
  let [currentUser, setCurrentUser] = useState(null);
  currentUser = JSON.parse(localStorage.getItem("user"));

  return (
    <div>
      {currentUser ? (
        <Overview info={currentUser} />  // Korrektes Übergeben des currentUser als Prop
      ) : (
        window.location.reload()
      )}
    </div>
  );
};

export default Home;
