/////////////////////////////////////////////////////////////////////////////////////
//
// Projekt Tierwohl-KI
// COPYRIGHT (c)2023 by novutrack GmbH
//
// Photos.js
// Fotos Page der Anwendung
//
// Author:          Markus Seidl
// Erstellt am:     29.02.2024
// Letzte Änderung: 03.09.2024
// von:             Florian Karner
//
///////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Card,
  Table,
  Form,
  Row,
  Col,
  Pagination,
  Button,
} from "react-bootstrap";
import axios from "axios";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import moment from 'moment';
import { LightboxKey, Team } from "../env.js";

import { SlideshowLightbox, initLightboxJS } from "lightbox.js-react";
import "lightbox.js-react/";
import "../css/imageCarousel.scss";
import "lightbox.js-react/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import defaultImage from "../images/logo_CW.png";
import { Store } from "react-notifications-component";
import '@fortawesome/fontawesome-free/css/all.min.css';


function StallungenTable() {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [folk, setFolk] = useState([]);
  const [selectedFolkID, setSelectedFolkID] = useState("");
  const [tableData, setTableData] = useState([]);


  useEffect(() => {
    const fetchFolk = async () => {
      try {
        const operationID = currentUser.betriebID;
        const res = await axios.get("/api/folk", {
          params: { operationID },
        });
        setFolk(res.data);
        
        if (res.data.length > 0) {
          setSelectedFolkID(res.data[0].id.toString());
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchFolk();
  }, [currentUser.betriebID]);

///TODO /selectTillCurrentDate
// In Verwendung außerhalb von Showcases sollte 'datas/' verwendet werden, da /selectTillCurrentDate nur die Daten bis zum aktuellen Datum zurück gibt
  const fetchData = async () => {
    if (!selectedFolkID) return;
    try {
      const res = await axios.get("/api/datas/selectTillCurrentDate", {
        params: { selectedFolkID },
      });

      setTableData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedFolkID]);

  const handleDropdownChange = (e) => {
    setSelectedFolkID(e.target.value);
  };

  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    initLightboxJS(LightboxKey, Team);
  });

  

  const closeLightbox = () => {
    setIsOpen(false);
  };
  window.moment = moment;
  moment.locale();

  //musste die logik umdrehen das es klappt mit der filterung
  const positivOptions = [
    { id: 0, label: 'Positiv' },
    { id: 1, label: 'Negativ' },
  ];
  



  /**
   * Spalten und Überschriften
   */
  const columns = [
    {
      name: "id",
      header: "ID",
      minWidth: 50,
      maxWidth: 100,
      defaultFlex: 1,
      filterEditor: NumberFilter,
    },
    {
      name: 'datum',
      header: 'Datum Uhrzeit',
      defaultFlex: 1,
      dateFormat: 'DD.MM.YYYY HH:mm', // korrektes Format hier einstellen
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        return {
          dateFormat: 'YYYY-MM-DD', // oder welches Format Sie für den Filter bevorzugen
          placeholder: index === 1 ? '' : '' // oder entsprechenden Platzhaltertext
        };
      },
      render: ({ value }) => moment(value).format('DD.MM.YYYY HH:mm'), // Formatierung hier
    },
    {
      name: "stallbezeichnung",
      header: "Stallung",
      type: "string",
      minWidth: 150,
      defaultFlex: 1,
    },
    {
      name: "token",
      header: "Chickenwatcher",
      type: "string",
      minWidth: 150,
      defaultFlex: 1,
    },
    {
      name: "gewicht",
      header: "Gewicht in g",
      type: "string",
      minWidth: 150,
      defaultFlex: 1,
    },
    {
      name: "helligkeit",
      header: "Helligkeit in lux",
      minWidth: 150,
      defaultFlex: 1,
    },
    {
      enableColumnFilterContextMenu: false,
      name: "positiv",
      header: "Befund",
      minWidth: 150,
      defaultFlex: 1,
      filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: 'Alle',
          dataSource: positivOptions,
        },
        render: ({ value }) => 
          value === 1 
            ? <span style={{ color: 'red' }}>Positiv</span> 
            : value === 0 
              ? "Negativ" 
              : ""

      },
      {
        name: "statistik",
        header: "Tagesstatistik",
        minWidth: 50,
        maxWidth: 150,
        render: ({ data }) => {
          if (data.statistik === 0) {
            return (
              <div>
                <Button
                  className="button-clear green"
                  onClick={() => handleIgnoreClick(data)}
                >
                  <i class="fas fa-check"></i>
                </Button>
              </div>
            );
          } else if (data.statistik === 1) {
            return (
              <div>
                <Button
                  className="button-clear red"
                  onClick={() => handleIgnoredClick(data)}
                >
                  <i class="fas fa-times"></i>
                </Button>
              </div>
            );
          } else {
            return <div>Keine Aktion</div>;
          }
        },
      }
      
  ];

  const handleIgnoreClick = async (rowData) => {
    const dataId = rowData.id;
    const status = 1;
  
    try {
      // Stelle sicher, dass die PUT-Anfrage korrekt formatiert ist
      const response = await axios.put("/api/datas/updateData", { dataId, status });
      const updatedRow = response.data;
      
      fetchData();

    } catch (err) {
      // Fehlermeldung
      Store.addNotification({
        title: "Fehler",
        message: "Beim Aktualisieren der Daten ist ein Fehler aufgetreten.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true
        }
      });
    }
  };
  

  const handleIgnoredClick = async (rowData) => {


    const dataId = rowData.id;
    const status = 0;
  
    try {
      // Stelle sicher, dass die PUT-Anfrage korrekt formatiert ist
      await axios.put("/api/datas/updateData", { dataId, status });
      
      fetchData();

    } catch (err) {    
      // Fehlermeldung
      Store.addNotification({
        title: "Fehler",
        message: "Beim Aktualisieren der Daten ist ein Fehler aufgetreten.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 6000,
          onScreen: true
        }
      });
    }
  };

  /**
   * Anlegen von übergeordneten Gruppen
   */
  const groups = [{ name: "other", header: "Sonstiges" }];

  const defaultSortInfo = { name: 'datum', dir: -1 }
  /**
   * Filterung
   */
  const filterValue = [
    { name: "id", operator: "gte", type: "number" },
    {
      name: "datum",
      operator: "afterOrOn",
      type: "date",
      value: "",
      dir: -1
    },
    { name: "stallbezeichnung", operator: "startsWith", type: "string", value: "" },
    { name: "token", operator: "eq", type: "string", value: "" },
    { name: "gewicht", operator: "gte", type: "number", value: "" },
    { name: "helligkeit", operator: "gte", type: "number", value: "" },
    { name: "positiv", operator: "neq", type: "select", value: "" },
  ];

  const [selected, setSelected] = useState({});

  const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
    setSelected(data[0]);
    const selectedRow = data[0];

    const updatedImages = [
      {
        name: "fotoOben",
        token: selectedRow.token,
        src: selectedRow.fotoOben.split("/").pop(),
      },
      {
        name: "fotoUnten",
        token: selectedRow.token,
        src: selectedRow.fotoUnten.split("/").pop(),
      },
      {
        name: "fotoLinks",
        token: selectedRow.token,
        src: selectedRow.fotoLinks.split("/").pop(),
      },
      {
        name: "fotoRechts",
        token: selectedRow.token,
        src: selectedRow.fotoRechts.split("/").pop(),
      },
      


    ];

    setImages(updatedImages);
  }, []);

  const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
    pageText:'Seite ',
    ofText:' von ',
    perPageText:'Ergebnisse pro Seite',
    showingText:'Zeigt '

  })
  
  const selectedHerde = folk.find(herde => herde.id.toString() === selectedFolkID);

  const gridStyle = { minHeight: 550 };

  return (
    <Container fluid>
      <div>
        <div className="main">
          <Row>
            <Col>
              <div className="contentbox">
                <h1 className="title">
                  Herden Daten
                </h1>
                <Card className="shadow">
                  <Card.Header className="py-3 d-flex justify-content-between align-items-center">
                    <p className="m-0 fw-bold">Herden Daten</p>
                    {selectedHerde ? (
                      <div>
                        <b>Rasse:</b> {selectedHerde.rassenBezeichnung } &emsp;
                        <b>Einstalldatum:</b> {selectedHerde.einstallDatum+" "} &emsp;
                        <b>Ausstalldatum:</b> {selectedHerde.ausstallDatum} 
                      </div>
                    ) : (
                      <div>Herde auswählen</div>
                    )}
        

                    <select
                    style={{ width: "auto" }}
                    className="form-control"
                    value={selectedFolkID}
                    onChange={handleDropdownChange}
                    required
                  >
                    {folk.map((herde) => (
                      <option key={herde.id} value={herde.id}>
                        {herde.bezeichnung}
                      </option>
                    ))}
                  </select>
                  </Card.Header>

                  <Card.Body>
                    <Row>
                      <ReactDataGrid
                        idProperty="organisatorID"
                        defaultFilterValue={filterValue}
                        columns={columns}
                        groups={groups}
                        editable={false}
                        dataSource={tableData}
                        style={gridStyle}
                        defaultLimit={10}
                        selected={selected}
                        onSelectionChange={onSelectionChange}
                        pagination
                        i18n={i18n}
                        defaultSortInfo={defaultSortInfo}
  
                      />
                    </Row>

                    <Row>
                      {/* Lightbox */}
                      <div className="divSlideshowLightbox">
                        <h1 className="title">Fotos</h1>
                        <SlideshowLightbox
                          key={images.length}
                          className=""
                          showThumbnails={true}
                          images={images.map((image, index) => {
                            return {
                              src: `/api/photos/${image.token}/${image.src}`,
        
                            };
                          })}
                          isOpen={isOpen}
                          onClose={closeLightbox}
                          currentIndex={currentImageIndex}
                        />
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default StallungenTable;
